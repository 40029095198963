<div *ngFor="let col of data">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{col.operacion}}
      </mat-panel-title>
      <mat-panel-description>
        {{col.nameUser}}
        <span class="etiqueta_descriptiva">
          Estado Operación
        </span>
        <span class="etiqueta_descriptiva">
          {{col.textState}}
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="bodyText"><span class="spanBody">Estado del correo: </span>{{col.smtpState}}</div>
    <div class="bodyText"><span class="spanBody">Inicio: </span>{{col.dateAccess}}</div>
    <div class="bodyText"><span class="spanBody">Fin: </span>{{col.dateClose}}</div>
    <div class="bodyText"><span class="spanBody">Email: </span>{{col.mailUser}}</div>
  </mat-expansion-panel>
</div>
