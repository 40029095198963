import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { displayedColumns } from './models/circuits.model';
@Component({
  selector: 'app-circuits',
  templateUrl: './circuits.component.html',
  styleUrls: ['./circuits.component.scss']
})
export class CircuitsComponent implements OnInit, AfterViewInit {

  detailButton: boolean = false;

  idOperation: string;

  dataSource: any[] = [
    {id: 282772, circuitTitle: "Prueba1", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282775, circuitTitle: "Prueba1", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282776, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282778, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282779, circuitTitle: "Prueb1", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282775, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 2827759, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282772, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282775, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282775, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
    {id: 282775, circuitTitle: "Prueba", externalId: null, nameCreator: "Integrator user", nameArea: "Default", nameAreaParent: "Nueva Area", createDate: "2022-06-15T00:35:15", circuitType: "TISIGORGA", closeDate: null, circuitTotalCredits: 23, stateTitle: "Activa"},
  ];

  columns = displayedColumns;
  positionTable1: string = 'horizontal';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.dataSource;
  }

  ngAfterViewInit() {}

  /**
   *
   * @param event
   */
  onRowDblClick(event: any) {
    this.router.navigate(['/detail', event]);
  }

  /**
   * Function that sets to enable/disable the actual button
   */
  onRowSimpleClick(event: any){
    this.detailButton = true;
    this.idOperation = event;

  }

  redirectDetail(){
    this.router.navigate(['/detail', this.idOperation]);
  }

  newData(event) {
    this.dataSource = event;
  }
}
