import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'iv-expansion',
  templateUrl: './iv-expansion.component.html',
  styleUrls: ['./iv-expansion.component.scss']
})
export class IvExpansionComponent implements OnInit {

  @Input() data: any[];

  constructor() { }

  ngOnInit(): void {
    this.data;
    this.assignTextState();
  }

  assignTextState(){
    this.data?.map(element => {
      element['textState'] = this.converTextState(element.idEstado);
    })
  }

  converTextState(idState) {
    switch(idState){
      case 0:
      return 'Pendiente';
      case 1:
      return 'Activa';
      case 2:
      return 'Finalizada';
      case 3:
      return 'Documentación rechazada';
      case 4:
      return 'Rechazada';
      case 5:
      return 'Caducada';
      case 6:
      return 'Cancelada';
      case 7:
      return 'Pendiente entrega';
      case 8:
      return 'Bloqueada';
      case 9:
      return 'No entregado';
    }
  }

}
