import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CircuitsComponent } from './pages/circuits/circuits.component';
import { DetailCircuitComponent } from './pages/detail-circuit/detail-circuit.component';
import { LoginComponent } from './pages/login/login.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'circuits', component: CircuitsComponent},
  { path: 'detail/:id', component: DetailCircuitComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
