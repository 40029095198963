import { InvokeFunctionExpr } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { IColumns } from '../../interfaces/IColumns';

@Component({
  selector: 'IvTable',
  templateUrl: './iv-table.component.html',
  styleUrls: ['./iv-table.component.scss']
})
export class IvTableComponent implements OnInit {

  @Output() onRowDblClick = new EventEmitter();

  @Output() onRowClick = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() data;
  @Input() dataHorizontal: any;
  @Input() displayedColumns: IColumns[];
  @Input() position: string = 'horizontal';

  isSelected = {};

  keyColumns: string[] = [];

  dataHorizontalConver: string[];

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns;
    this.converDisplayedColumns();
    this.keyColumns;
    if(this.dataHorizontal){
      this.convertObject();
    }
  }

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }


  converDisplayedColumns(){
    this.displayedColumns?.forEach(column => {
      this.keyColumns.push(column.key);
    })
  }

  convertObject(){
    this.dataHorizontalConver = Object.values(this.dataHorizontal);
  }

  onRowDoubleClick(row) {
    this.onRowDblClick.emit(row.id);
  }

  onRowSimpleClick(row){
    this.onRowClick.emit(row.id);
    this.isSelected = row;
  }

}
