export const displayedColumns: any =[
  {
    key: 'id',
    label: 'ID',
    value: '',
    type: 'number'
  },
  {
    key: 'externalId',
    label: 'Referencia',
    value: '',
    type: 'text'
  },
  {
    key: 'Title',
    label: 'Asunto',
    value: '',
    type: 'text'
  },
  {
    key: 'nameTypeCircuit',
    label: 'Tipo de circuito',
    value: '',
    type: 'text'
  },
  {
    key: 'FReg',
    label: 'Fecha de activación',
    value: '',
    type: 'date'
  },
  {
    key: 'estadoCircuito',
    label: 'Estado circuito',
    value: '',
    type: 'text'
  },
  {
    key: 'motivoRechazo',
    label: 'Motivo de rechazo',
    value: '',
    type: 'text'
  },
  {
    key: 'FExpired',
    label: 'Fecha de cierre',
    value: '',
    type: 'date'
  },
  {
    key: 'circuitTotalCredits',
    label: 'Créditos',
    value: '',
    type: 'number'
  },
  {
    key: 'metadatos',
    label: 'Metadatos circuitos',
    value: '',
    type: 'text'
  }
];


export const displayedColumns2: any = [
  {
    key: 'fileNameWithExtension',
    label: 'Nombre Archivo',
    value: '',
    type: 'text'
  },
  {
    key: 'typeName',
    label: 'Tipo',
    value: '',
    type: 'text'
  },
  {
    key: 'csv',
    label: 'CSV',
    value: '',
    type: 'text'
  },
  {
    key: 'custodyInitDate',
    label: 'Custodia desde',
    value: '',
    type: 'text'
  },
  {
    key: 'custodyEndDate',
    label: 'Custodia hasta',
    value: '',
    type: 'text'
  }
]


export const displayedColumns3: any = [
  {
    key: 'custodyEndDate',
    label: 'Custodia hasta',
    value: '',
    type: 'text'
  }
]


