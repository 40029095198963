import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'IvSearch',
  templateUrl: './iv-search.component.html',
  styleUrls: ['./iv-search.component.scss']
})
export class IvSearchComponent implements OnInit {
  initData = [];
  @Input() displayedColumns;
  @Input() data;
  @Output() newData = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  search() {
    const filters: any = [];
    this.initData = this.data;
    for (let i = 0; i < this.displayedColumns.length; i++) {
      let data = this.displayedColumns[i];
      let val = data.value;
      if (val || val === null) {
        filters.push({
          key: this.displayedColumns[i].key,
          value: val
        });
      }
    }
    for (let i = 0; i < filters.length; i++) {
      const key = filters[i].key;
      const value = filters[i].value;
      const newData = this.data.filter(res => res[key] == value);
      this.newData.emit(newData);
    };
  }

  clearFilters() {
    for (let i = 0; i < this.displayedColumns.length; i++) {
      this.displayedColumns[i].value = '';
    }
    this.newData.emit(this.initData);
  }

}
