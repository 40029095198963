import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IColumns } from '../../interfaces/IColumns';
import { displayedColumns, displayedColumns2 } from './models/detail.model';
@Component({
  selector: 'app-detail-circuit',
  templateUrl: './detail-circuit.component.html',
  styleUrls: ['./detail-circuit.component.scss']
})
export class DetailCircuitComponent implements OnInit, AfterViewInit {

  positionTable1: string = 'vertical';
  positionTable2: string = 'vertical';



  dataCircuit = {
    id: 282812,
    externalId: null,
    Title: "Prueba",
    nameTypeCircuit: "TI_ReprocessSiGORGA",
    FReg: "2022-06-17T00:35:23",
    estadoCircuito: "Activa",
    motivoRechazo: 'Rechazado',
    FExpired: '2022-06-17T00:35:23',
    circuitTotalCredits: 5,
    metadatos: []
  }

  dataDoc = {
    fileNameWithExtension: "Hola.pdf",
    typeName: "Principal",
    csv: "cdcbbb2b-c5f4-4db9-bd6e-3a2871ab6a43",
    custodyIniDate: "2022-06-15T00:35:15",
    custodyEndDate: "2032-06-15T00:35:15"
  }

  columns: IColumns[];
  columns2: IColumns[];

  constructor() { }

  operations = [
    {
      idEstado: 2,
      dateAccess: "2022-06-13T09:05:05",
      dateClose: "2022-06-13T09:05:35",
      mailUser: "sergio.torrome@signaturit.com",
      nameUser: "Sergio Torrome",
      operacion: "Firma simple"
    },
    {
      idEstado: 2,
      dateAccess: "2022-06-13T09:05:05",
      dateClose: "2022-06-13T09:05:35",
      mailUser: "sergio.torrome@signaturit.com",
      nameUser: "Sergio Torrome",
      operacion: "Firma simple"
    },
    {
      idEstado: 2,
      dateAccess: "2022-06-13T09:05:05",
      dateClose: "2022-06-13T09:05:35",
      mailUser: "sergio.torrome@signaturit.com",
      nameUser: "Sergio Torrome",
      operacion: "Firma simple"
    },
]

  ngOnInit(): void {
    this.loadSMTP(250);
    this.operations;
    this.columns = displayedColumns;
    this.columns2 = displayedColumns2;
  }

  ngAfterViewInit() {}


  loadSMTP(smtpCode){
    for(let i = 0; i < this.operations.length; i++){
      switch(smtpCode) {
        case 0:
          this.operations[i]['smtpState'] = 'Pendiente de entrega';
          break;
        case 250:
          this.operations[i]['smtpState'] = 'Correo entregado';
          break;
        case 451:
          this.operations[i]['smtpState'] = 'Error de conexión';
          break;
        default:
          this.operations[i]['smtpState'] = 'Correo no entregado';
          break;
      }
    }

  }
}
