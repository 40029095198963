

export const displayedColumns: any =[
  {
    key: 'id',
    label: 'ID',
    value: '',
    type: 'number'
  },
  {
    key: 'circuitTitle',
    label: 'Asunto',
    value: '',
    type: 'text'
  },
  {
    key: 'externalId',
    label: 'Referencia',
    value: '',
    type: 'text'
  },
  {
    key: 'nameCreator',
    label: 'Creador',
    value: '',
    type: 'text'
  },
  {
    key: 'nameArea',
    label: 'Área',
    value: '',
    type: 'text'
  },
  {
    key: 'nameAreaParent',
    label: 'Área Padre',
    value: '',
    type: 'text'
  },
  {
    key: 'createDate',
    label: 'Fecha activación',
    value: '',
    type: 'text'
  },
  {
    key: 'circuitType',
    label: 'Tipo',
    value: '',
    type: 'text'
  },
  {
    key: 'closeDate',
    label: 'Fecha cierre',
    value: '',
    type: 'text'
  },
  {
    key: 'circuitTotalCredits',
    label: 'Créditos',
    value: '',
    type: 'number'
  },
  {
    key: 'stateTitle',
    label: 'Estado',
    value: '',
    type: 'text'
  }];
