import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { CircuitsComponent } from './pages/circuits/circuits.component';
import { IvTableComponent } from './components/iv-table/iv-table.component';
import { SharedModule } from './shared/shared.module';
import { DetailCircuitComponent } from './pages/detail-circuit/detail-circuit.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { IvSearchComponent } from './components/iv-search/iv-search.component';
import { IvExpansionComponent } from './components/iv-expansion/iv-expansion.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CircuitsComponent,
    IvTableComponent,
    DetailCircuitComponent,
    TopbarComponent,
    LoadingComponent,
    IvSearchComponent,
    IvExpansionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
