<div class="login-body">
  <div class="login-container">
    <mat-card class="text-center login-card">
      <img src="assets/images/logo-office.jpg" class="login-main-logo">
    </mat-card>
    <mat-card class="form">
      <form [formGroup]="loginForm">
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Usuario</mat-label>
          <input formControlName="name" matInput placeholder="Introduzca un usuario" required>
        </mat-form-field>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Contraseña</mat-label>
          <input type="password" formControlName="password" matInput placeholder="Introduzca una contraseña" required>
        </mat-form-field>
        <button (click)="login()" id="button" class="fullWidth" [disabled]="!loginForm.valid" mat-stroked-button>Iniciar sesión</button>
      </form>
    </mat-card>
  </div>
</div>
