import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  loginForm = new FormGroup({
    name: new FormControl(),
    password: new FormControl
 });

  constructor(
    private _router: Router,
    // private _auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {}


  login() {
    const user = this.loginForm.controls.name.value;
    const password = this.loginForm.controls.password.value;
    this._router.navigate(['/circuits']);
  }

}
