<div class="container">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-bold">
          <mat-icon>search</mat-icon><span>Buscar circuito</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card style="margin: 20px">
        <div class="row">
          <div class="item" *ngFor="let column of displayedColumns; let i= index">
            <mat-form-field>
              <input [type]="column.type" (keyup.enter)="search()" [(ngModel)]="column.value" name="{{column.key}}"
                matInput [placeholder]="column.label">
            </mat-form-field>
          </div>
        </div>
        <div class="buttons">
          <button (click)="clearFilters()" class="cleanButton" mat-button>Limpiar</button>
          <button (click)="search()" mat-button>Buscar</button>
        </div>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</div>
