<div class="container" *ngIf="position === 'horizontal'">
  <table mat-table [dataSource]="data" class="mat-elevation-z8" id="tableCont">
    <div *ngFor="let col of displayedColumns; let i = index">
      <ng-container [matColumnDef]="col.key">
        <th mat-header-cell *matHeaderCellDef> <span>{{col.label}}</span></th>
        <td mat-cell *matCellDef="let element"> <span class="textContent">{{element[col.key]}}</span> </td>
      </ng-container>
    </div>
      <tr mat-header-row *matHeaderRowDef="keyColumns" style="background-color: #f8f4fc;"></tr>
      <tr [ngClass]="{active: isSelected === row}" (dblclick)="onRowDoubleClick(row)" (click)="onRowSimpleClick(row)" mat-row *matRowDef="let row; columns: keyColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  aria-label="Select page of periodic elements">
</mat-paginator>
</div>

<div style="width: 50%;" *ngIf="position === 'vertical'">
  <table style="margin: 25px;">
      <tr *ngFor="let col of displayedColumns; let i = index" style="width: 100%; display: flex;">
        <td style="width: 450px; border-bottom: 2px solid lightgrey; padding-top: 10px; padding-bottom: 10px;"><span style="font-weight: 500; margin-left: 10px;">{{col.label}}</span></td>
        <td style="width: 450px; border-bottom: 2px solid lightgrey; padding-top: 10px; padding-bottom: 10px;"><span style="margin-left: 10px;">{{dataHorizontalConver[i]}}</span></td>
      </tr>
  </table>
</div>
